// Imports

import React from "react"

import medicalFormsStyles from "./medical-forms.module.css"
import MedicalForm from "../../components/medical-form/medical-form"

// Header

const MedicalForms = ({ forms }) => {
  return (
    <div className={medicalFormsStyles.container}>
      <div className={medicalFormsStyles.widthContainer}>
        {forms.map(form => (
          <MedicalForm form={form.node} key={form.node.id} />
        ))}
      </div>
    </div>
  )
}

export default MedicalForms
