// Imports

import React from "react"
import PropTypes from "prop-types"

import medicalFormStyles from "./medical-form.module.css"
import DownloadButton from "../../components/download-button/download-button"

// Header

const MedicalForm = ({ form }) => {
  console.log(form)
  const formData = form.childMarkdownRemark.frontmatter
  // const sizeString = calculateSizeString(form.file.details.size)
  return (
    <div className={medicalFormStyles.container}>
      <img className={medicalFormStyles.img} src={formData.image} alt="Form" />
      <h4 className={medicalFormStyles.title}>{formData.title}</h4>
      <h5 className={medicalFormStyles.pdfTitle}>{formData.filename}</h5>
      <DownloadButton url={formData.file} />
    </div>
  )
}

MedicalForm.propTypes = {
  title: PropTypes.string,
  pdfTitle: PropTypes.string,
  size: PropTypes.string,
  imgSrc: PropTypes.string,
}

MedicalForm.defaultProps = {
  title: "Title",
  pdfTitle: "Filename",
  size: "file size",
  imgSrc: "",
}

export default MedicalForm
