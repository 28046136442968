// Imports

import React from "react"
import PropTypes from "prop-types"
import download from "downloadjs"

import downloadButtonStyles from "./download-button.module.css"

// Header

class DownloadButton extends React.Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const downloadUrl = `${this.props.url}`
    console.log(downloadUrl)
    if (downloadUrl) {
      download(downloadUrl)
    }
  }

  render() {
    return (
      <>
        <button onClick={this.handleClick} className={downloadButtonStyles.button}>Download</button>
      </>
    )
  }
}

DownloadButton.propTypes = {
  url: PropTypes.string,
}

DownloadButton.defaultProps = {
  url: ``,
}

export default DownloadButton
